import * as Colors from '@/theme/colors';
import { createMuiTheme } from '@material-ui/core/styles';
import type { PaletteOptions } from '@material-ui/core/styles/createPalette';

declare module '@material-ui/core/styles/createBreakpoints' {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    qhd: true;
    xl: true;
  }
}

const palette: PaletteOptions = {
  primary: {
    main: Colors.primary,
  },
  info: {
    main: Colors.info,
  },
  success: {
    main: Colors.success,
  },
  warning: {
    main: Colors.warning,
  },
  error: {
    main: Colors.error,
  },
  secondary: {
    main: Colors.darkGrey,
  },
};

export const MuiTheme = createMuiTheme({
  palette,
  overrides: {
    MuiDialog: {
      root: {
        fontFamily: 'Roboto',
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: Colors.darkGrey16,
      },
    },
    MuiCheckbox: {
      colorPrimary: {
        color: Colors.darkGrey16,
        '&$checked': {
          color: Colors.primary,
        },
        '&$disabled': {
          color: Colors.darkGrey16,
        },
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: Colors.darkGrey,
        fontSize: '12px',
        fontWeight: 'normal',
      },
    },
    MuiRadio: {
      colorPrimary: {
        color: Colors.darkGrey16,
        '&$checked': {
          color: Colors.primary,
        },
        '&$disabled': {
          color: Colors.darkGrey16,
        },
      },
      colorSecondary: {
        color: Colors.darkGrey16,
        '&$checked': {
          color: Colors.primary,
        },
        '&$disabled': {
          color: Colors.darkGrey16,
        },
      },
    },
    MuiSelect: {
      root: {
        letterSpacing: '0.4px',
        color: Colors.darkGrey,
      },
    },
    MuiTab: {
      textColorInherit: {
        color: Colors.darkGrey,
        opacity: 1,
        '&$selected, &:not($disabled):hover': {
          color: Colors.primary,
        },
      },
      root: {
        color: Colors.darkGrey,
        '&$selected': {
          color: Colors.primary,
        },
        '&$disabled': {
          color: Colors.disabled,
        },
      },
    },
    MuiListItem: {
      root: {
        color: Colors.darkGrey,
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: undefined,
        marginRight: '16px',
      },
    },
    MuiCircularProgress: {
      colorPrimary: {
        color: Colors.primary,
      },
      colorSecondary: {
        color: Colors.green,
      },
    },
    MuiChip: {
      root: { fontSize: '13px' },
      labelSmall: {
        paddingLeft: '12px',
        paddingRight: '12px',
      },
      colorSecondary: { backgroundColor: Colors.darkGrey86 },
    },
    MuiTypography: {
      root: {
        color: Colors.darkGrey,
      },
    },
    MuiTabs: {
      indicator: {
        height: '3px',
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: '0 2px 4px 0 rgba(16, 32, 39, 0.16)',
      },
    },
    MuiCardContent: {
      root: { paddingBottom: '16px !important' },
    },
    MuiButton: {
      outlined: {
        backgroundColor: Colors.white,
      },
    },
  },
  typography: {
    fontFamily: [
      `Roboto`,
      '-apple-system',
      'BlinkMacSystemFont',
      'Segoe UI',
      'PingFang SC',
      'Hiragino Sans GB',
      'Microsoft YaHei',
      'Helvetica Neue',
      'Helvetica',
      'Arial',
      'sans-serif',
      'Apple Color Emoji',
      'Segoe UI Emoji',
      'Segoe UI Symbol',
    ].join(','),
    button: {
      letterSpacing: '1.25px',
    },
    subtitle1: {
      fontWeight: 500,
      lineHeight: 1.5,
      fontSize: '1rem',
      fontStretch: 'normal',
      fontStyle: 'normal',
      letterSpacing: '0.15px',
    },
    subtitle2: {
      letterSpacing: '0.1px',
    },
    body2: {
      fontSize: '0.875rem',
      fontWeight: 'normal',
      fontStretch: 'normal',
      lineHeight: 1.43,
      letterSpacing: '0.25px',
    },
    caption: {
      letterSpacing: '0.4px',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      qhd: 1440,
      xl: 1920,
    },
  },
});
