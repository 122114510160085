import { PageURL } from '@/pageUrls';
import { isOfflineApi } from '@/utils/api';
import { lazy } from 'react';

export const safeRoutes = [
  { pathname: '/' },
  { pathname: PageURL.SPLASH },
  { pathname: PageURL.PLUG_IN_SMART_HUB },
  { pathname: PageURL.CONNECT_TO_SMART_HUB },
  {
    pathname: PageURL.DEVICES,
    requiredParams: ['serialNumber', isOfflineApi() ? 'returnTo' : 'unitId'],
  },
  { pathname: PageURL.SELECT_UNIT, requiredParams: ['serialNumber'] },
];

const SecurityLayout = lazy(() => import('@/layouts/SecurityLayout'));
const ContactMaintenance = lazy(() => import('@/pages/ContactMaintenance'));
const DevicePage = lazy(() => import('@/pages/Devices/DevicePage'));
const Devices = lazy(() => import('@/pages/Devices'));
const SelectUnitPage = lazy(() => import('@/pages/SelectUnitPage'));
const Splash = lazy(() => import('@/pages/Splash'));
const PlugInSmartHubPage = lazy(() => import('@/pages/PlugInSmartHubPage'));
const ConnectToSmartHubPage = lazy(() => import('@/pages/ConnectToSmartHubPage'));

export type PropifyRoute = {
  path: string;
  component?: Function;
  routes?: PropifyRoute[];
  redirect?: string;
  props?: Record<string, unknown>;
};

export const routes: PropifyRoute[] = [
  {
    path: '/',
    component: SecurityLayout,
    routes: [
      {
        path: '/',
        redirect: isOfflineApi() ? PageURL.DEVICES : PageURL.SPLASH,
      },
      {
        path: PageURL.SPLASH,
        component: Splash,
      },
      {
        path: PageURL.PLUG_IN_SMART_HUB,
        component: PlugInSmartHubPage,
      },
      {
        path: PageURL.CONNECT_TO_SMART_HUB,
        component: ConnectToSmartHubPage,
      },
      {
        path: PageURL.SELECT_UNIT,
        component: SelectUnitPage,
      },
      {
        path: PageURL.DEVICES,
        component: DevicePage,
        routes: [
          {
            path: PageURL.DEVICES,
            component: Devices,
          },
        ],
      },
      {
        path: PageURL.CONTACT_MAINTENANCE,
        component: ContactMaintenance,
      },
    ],
  },
];
