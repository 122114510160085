import NotFoundSvg from '@/assets/404.svg?react';
import Button from '@/components/Button';
import { PageURL } from '@/pageUrls';
import * as Colors from '@/theme/colors';
import { isOfflineApi } from '@/utils/api';
import { Box, makeStyles, Typography } from '@/utils/material';
import type { FC } from 'react';
import { useNavigate } from 'react-router';

const useStyles = makeStyles({
  title: {
    textAlign: 'center',
    marginBottom: '12px',
  },
  subtitle: {
    textAlign: 'center',
    color: Colors.disabled,
    marginBottom: '32px',
  },
});

const NoFoundPage: FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleBackHome = () => navigate(isOfflineApi() ? PageURL.DEVICES : PageURL.SPLASH);

  return (
    <Box display="flex" flexDirection="column" alignItems="center" pt={3}>
      <NotFoundSvg />
      <Box flexDirection="column" alignItems="center" mt={3}>
        <Typography variant="h5" className={classes.title}>
          404
        </Typography>
        <Typography variant="body2" className={classes.subtitle}>
          Sorry, the page you visited does not exist.
        </Typography>
        <Box textAlign="center">
          <Button color="primary" onClick={handleBackHome}>
            Back Home
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default NoFoundPage;
