import App from '@/App';
import '@/index.css';
import { SentryService } from '@/services/sentry';
import React from 'react';
import ReactDOM from 'react-dom';

SentryService.init();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
